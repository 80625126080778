<template>
  <div class="hello" style="width: 620px; margin: auto;">
    <h1>{{ msg }}</h1>
    <p>We are a primary care membership that will radically improve your health and longevity. We do this by providing
      direct access to trusted physician experts and to the latest medical technology while keeping you in the driver’s
      seat. We believe that primary care should be local and often best done in-person, but it can also be virtual where
      it makes sense. We address many aspects of the broken healthcare system.</p>
    <h3>Naba Health Provides:</h3>
    <ul>
      <li>Direct access to trusted physician experts</li>
      <li>Access to the latest evidence-based treatments</li>
      <li>Prevention and disease reversal focus</li>
      <li>Flexibility of in-person or virtual</li>
      <li>Premium services and amenities</li>
      <li>Personal health record (PHR)</li>
      <li>Tech-pervasive experience</li>
    </ul>
    <!-- <form id="cta" @submit.prevent="submitEmail">
      <input v-model="email" />
      <button>Join</button>
    </form> -->
    <h3>Email:</h3> <a href="mailto:support@naba.health">support@naba.health</a>
    <h3>Phone:</h3> 703.810.3868
    <h3>Address:</h3>
    <p>1751 Pinnacle Drive, Suite 600 <br />
      Tysons, VA 22102</p>
    <h3>Social Links</h3>
    <ul class="footer">
      <li v-for="link in links" :key="link.url"><a :href="link.url" target="_blank" rel="noopener">{{ link.name }}</a>
      </li>
    </ul>
    <h3>Terms & Policies</h3>
    <ul class="footer">
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vRcg4V-T5SKHFlFGcZlbDtURSa52wFcGnEnnqdC8dCyuaqSGKMcKaEImTcKFxcniDGaaaKp84v0P40o/pub">Terms
          of Service</a></li>
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vTXCeNwX8YhH_KFpBuN7kiOEclM625-KvNS_I5ZG0Fm5hoSD_XCiVpaP_Sm7qVJn-AyF4Db6N8NosSd/pub">Privacy
          Policy</a></li>
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vQYz8UVeqjm0Vgq-GM2Z8WTobJh2wyWK5JxmM2fs-U3PhzRI0CgQ0W3VupzoBnz-99iJQMbS-GsYCUX/pub">Notice
          of Privacy Practices</a></li>
      <li><a
          href="https://docs.google.com/document/d/e/2PACX-1vSOgYaahdoSBSWPYHXD8s2EIhhcPlkoxmJ2iDrBbaWDOuywANdlWhgTDY7Zo1ZnAR12WNmw7R1_441_/pub">Membership
          Agreement</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    description: String
  },
  data() {
    return {
      email: 'Enter you email address',
      links: [
        {
          url: 'https://twitter.com/NabaHealth',
          name: 'Twitter'
        },
        {
          url: 'https://www.linkedin.com/company/nabahealth/',
          name: 'LinkedIn'
        },
        {
          url: 'https://www.facebook.com/NabaHealthInc',
          name: 'Facebook'
        },
        {
          url: 'https://www.instagram.com/naba.health/',
          name: 'Instagram'
        }
      ]
    }
  },
  methods: {
    submitEmail() {
      console.log(this.email);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul.footer>li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

form#cta>input {
  margin: 10px;
}
</style>
